import { hc } from "hono/client";
import { type AppType } from "@resposta-certa/api/src/app";

export const GetApiClient = (hostUrl: string, token: string | null) => {
  return hc<AppType>(hostUrl, {
    headers: {
      Authorization: token ?? "",
      UserTimezone: typeof window !== "undefined" ? new window.Intl.DateTimeFormat().resolvedOptions().timeZone
      : "",
    },
  });
};