import { GetApiClient } from "@resposta-certa/api-client";

export default function useApi() {
  const config = useRuntimeConfig();
  const token = useToken();
  const client = GetApiClient(config.public.apiURL as string, token.value);

  return client;
}

export { GetApiClient };
